// Have to be defined globally, otherwise overwrite doesn't work
.column-selector__item {
  display: block;
  padding: 0.5rem 1rem;
}

.column-selector__menu {
  &.mat-mdc-menu-panel {
    width: auto;
    max-width: none;

    .app--has-environment-banner ~ .cdk-overlay-container & {
      max-height: calc(100vh - 90px);
    }
  }
}
