// This is used in the toasty message component
.collapp-api-error-status {
  display: block;
  margin: 0.5rem 0 0;
  font-size: 0.6rem;
}

.collapp-api-error-date {
  display: block;
  margin: 0.5rem 0 0;
  font-size: 0.6rem;
}
