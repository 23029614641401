@import "../../base/variables";

// TODO MIGRATION @import "@angular/material/theming";
@import "./button-base";

// stylelint-disable no-duplicate-selectors

.collapp-button,
.collapp-icon-button,
.collapp-stroked-button,
.collapp-sharp-edge-button,
.collapp-flat-button {
  @include collapp-button-base;
}

.collapp-button,
.collapp-icon-button {
  .mat-button-focus-overlay {
    opacity: 0;
  }
}

.collapp-icon-button {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Only flat and stroked buttons (not raised, FABs or icon buttons) have a hover style.
// Use the same visual treatment for hover as for focus.
.collapp-button:hover .mat-button-focus-overlay,
.collapp-stroked-button:hover .mat-button-focus-overlay,
.collapp-sharp-edge-button:hover .mat-button-focus-overlay {
  opacity: 0.04;
}



// Disable the hover styles on non-hover devices. Since this is more of a progressive
// enhancement and not all desktop browsers support this kind of media query, we can't
// use something like `@media (hover)`.
@media (hover: none) {
  .collapp-button:hover .mat-button-focus-overlay,
  .collapp-stroked-button:hover .mat-button-focus-overlay,
  .collapp-sharp-edge-button:hover .mat-button-focus-overlay {
    opacity: 0;
  }
}

.collapp-stroked-button {
  // TODO MIGRATION @include mat-overridable-elevation(0);

  padding: $collapp-stroked-button-padding;
  border: 1px solid currentColor;
  line-height: $collapp-stroked-button-line-height;
}

.collapp-flat-button {
  // TODO MIGRATION @include mat-overridable-elevation(0);
}

.collapp-icon-button {
  flex-shrink: 0;
  width: 40px;

  // Reset the min-width from the button base.
  min-width: 0;
  height: 40px;
  padding: 0;
  border-radius: 50%;

  .mat-icon {
    line-height: 24px;
  }
}

// Elements inside of all type of buttons should be vertical aligned in the middle.
/* stylelint-disable-next-line */
.collapp-button, .collapp-icon-button, .collapp-flat-button, .collapp-sharp-edge-button, .collapp-stroked-button {
  // stylelint-disable-next-line selector-max-universal
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */

  /* stylelint-disable-next-line */
  .mat-button-wrapper > * {
    vertical-align: middle;
  }

  .button-spinner-wrapper {
    position: absolute;
    top: calc(50% - 8px);
    right: 8px;
    width: 16px;
    height: 16px;
  }
}

// Add an outline to make buttons more visible in high contrast mode. Stroked buttons
// don't need a special look in high-contrast mode, because those already have an outline.
// TODO MIGRATION @include cdk-high-contrast {
//  .collapp-button,
//  .collapp-icon-button,
//  .collapp-flat-button {
//    outline: solid 1px;
//  }/
// }

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .collapp-button,
  .collapp-icon-button,
  .collapp-flat-button,
  .collapp-sharp-edge-button,
  .collapp-stroked-button {
    margin: 0.75rem;
  }
}
// stylelint-enable
