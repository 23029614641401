@import "../base/variables";

/* Structure */
.collapp-table-wrapper {
  position: relative;
}

.collapp-table-loading-shade {
  display: flex;
  position: absolute;
  z-index: 101;
  inset: 0;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 15%);

  &--opaque {
    background-color: #ffffff;
  }
}

.collapp-table-container {
  display: block;
  position: relative;

  // The CollApp loading indicator has a dimension of 64x64
  min-height: 64px;
  overflow: auto;
}

.collapp-table-info {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 64px;
}

.collapp-list-table {
  width: 100%;

  .mat-mdc-row {
    transition: background-color ease-in-out 0.1s;
    cursor: pointer;

    &:hover {
      background-color: rgba(0 0 0 / 5%);
    }
  }

  .mat-sort-header-button {
    text-align: left;
  }

  .mat-mdc-header-cell,
  .mat-mdc-header-cell input,
  .mat-mdc-header-cell .mat-mdc-form-field {
    --mdc-filled-text-field-input-text-color: #0000008a;

    color: #0000008a;

    // --mat-table-header-headline-size: 12px !important;
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 12px !important;
  }

  /* .mat-sort-header.mat-mdc-header-cell {
    font-size: 12px;
    color: #0000008a;
  } */
}

.collapp-table-filter-cell {
  > .mat-mdc-form-field {
    --mdc-typography-body1-line-height: 10px;

    font-size: inherit;
    display: block;

    .mat-mdc-form-field-infix {
      width: auto;
      /* stylelint-disable-next-line declaration-no-important */
      padding: 0.4375em 0 !important;
      min-height: 10px;
    }

    .mat-mdc-form-field-icon-suffix {
      width: 16px;
      height: 16px;
      font-size: 12px;

      /* stylelint-disable-next-line */
      .mat-mdc-icon-button.mat-mdc-button-base {
        width: 16px;
        height: 16px;
        padding: 0;
        position:relative;
      }

      .mat-icon {
        width: 12px;
          height: 12px;
          font-size: 12px;
          position:absolute;
          top: 2px;
          left: 2px;
      }

      .mat-mdc-icon-button {
        /* stylelint-disable-next-line */
        .mat-mdc-button-touch-target {
          width: 12px;
          height: 12px;

          /* stylelint-disable-next-line */
        .mat-mdc-button-ripple,
        .mat-mdc-button-persistent-ripple {
          width: 12px;
          height: 12px;
        }
      }}
    }
  }
}

.collapp-responsible-list-table {
  .non-kpi {
    color: $grey;
    font-weight: 200;
  }

  .mat-mdc-table {
    width: 100%;
  }

  .mat-mdc-footer-cell {
    border-top: 1px solid rgba($black, 0.12);
    border-bottom: 0;
  }

  .mat-mdc-header-cell:last-of-type,
  .mat-mdc-footer-cell:last-of-type,
  .mat-mdc-cell:last-of-type {
    text-align: right;
  }
}

.collapp-responsible-list-table,
.collapp-available-resource-list-table {
  .person-list-item {
    padding-bottom: 0;
  }
}

.collapp-available-resource-list-table {
  .mat-mdc-header-cell:last-of-type,
  .mat-mdc-footer-cell:last-of-type,
  .mat-mdc-cell:last-of-type {
    text-align: right;
  }
}

// stylelint-disable selector-no-qualifying-type, selector-max-type, no-duplicate-selectors
.collapp-list-table {
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell,
  th.mat-mdc-header-cell {
    padding: 0 0.3125rem;

    &:first-child,
    &:first-child,
    &:first-child {
      padding-left: 24px;
    }

    &:last-child,
    &:last-child,
    &:last-child {
      padding-right: 24px;
    }
  }
}

.collapp-list-table__cell-content {
  vertical-align: text-bottom;
}

.collapp-list-table__chip-list {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;

  // margin: -4px;

  &:first-child {
    margin-left: -4px;
  }

  &:last-child {
    margin-right: -4px;
  }
}

.collapp-list-table__chip {
  display: inline-flex;
  align-items: center;
  height: 1px;
  margin: 4px;
  padding: 6px 7px;
  border-radius: 16px;
  opacity: 0.5;
  background-color: #6a7d8d;
  color: #ffffff;
  font-size: 10px;
  cursor: default;
}

.collapp-list-table__chip--warning {
  background-color: #ff6666;
}

.collapp-clear-input--hidden {
  position: relative;
  z-index: -1;
  opacity: 0;
  cursor: default;
}

.mat-column--error {
  color: $collapp-brand-accent;
}

[hidden] {
  display: none !important; // stylelint-disable-line declaration-no-important
}
// stylelint-enable

$stage-outlet-padding-top: 15px;
$stage-outlet-padding-bottom: 15px;
$stage-outlet-margin-top: 0;
$stage-outlet-margin-bottom: 50px;
/* stylelint-disable-next-line max-line-length */
$stage-outlet-offset: $stage-outlet-padding-top + $stage-outlet-padding-bottom + $stage-outlet-margin-top + $stage-outlet-margin-bottom;
$mat-paginator-height: 56px;

.collapp-table-container--sticky {
  max-height: calc(100vh - #{$stage-outlet-offset + $mat-paginator-height});

  .app--has-navbar & {
    max-height: calc(100vh - #{$collapp-sticky-navbar-height-sm + $stage-outlet-offset + $mat-paginator-height});
  }
}

@media (min-width: $screen-sm-min) {
  $stage-outlet-padding-top: 20px;
  /* stylelint-disable-next-line max-line-length */
  $stage-outlet-offset: $stage-outlet-padding-top + $stage-outlet-padding-bottom + $stage-outlet-margin-top + $stage-outlet-margin-bottom;

  .collapp-table-container--sticky {
    max-height: calc(100vh - #{$stage-outlet-offset + $mat-paginator-height});

    .app--has-navbar & {
      max-height: calc(100vh - #{$collapp-sticky-navbar-height-sm + $stage-outlet-offset + $mat-paginator-height});
    }
  }
}
