/* stylelint-disable selector-class-pattern */
@import "icon-variables";

@font-face {
  font-family: collapp-icons;
  font-style: normal;
  font-weight: 400;
  src:
    url("/assets/fonts/collapp-icons.ttf?u0jh9g") format("truetype"),
    // url("/assets/fonts/collapp-icons.woff2?u0jh9g") format("woff2"),
    url("/assets/fonts/collapp-icons.woff?u0jh9g") format("woff"),
    url("/assets/fonts/collapp-icons.svg?u0jh9g#collapp-icons") format("svg");
}

[class^="ic-"],
[class*="ic-"] {
  font-family: collapp-icons; // stylelint-disable-line font-family-no-missing-generic-family-keyword

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  speak: none;
}

.ic-external-link {
  &::before {
    content: $ic-external;
  }
}

.ic-info_outline {
  &::before {
    content: $ic-info_outline;
  }
}

.ic-info-outline {
  &::before {
    content: $ic-info_outline;
  }
}

.ic-return {
  &::before {
    content: $ic-Return;
  }
}

.ic-Superman {
  &::before {
    content: $ic-superman;
  }
}

.ic-add {
  &::before {
    content: $ic-add;
  }
}

.ic-arrow-back {
  &::before {
    content: $ic-arrow-back;
  }
}

.ic-bug {
  &::before {
    content: $ic-bug;
  }
}

.ic-checked {
  &::before {
    content: $ic-checked;
  }
}

.ic-close {
  &::before {
    content: $ic-close;
  }
}

.ic-delete {
  &::before {
    content: $ic-delete;
  }
}

.ic-download {
  &::before {
    content: $ic-download;
  }
}

.ic-dropDown {
  &::before {
    content: $ic-dropDown;
  }
}

.ic-edit {
  &::before {
    content: $ic-edit;
  }
}

.ic-event {
  &::before {
    content: $ic-event;
  }
}

.ic-keyboard_arrow_down {
  &::before {
    content: $ic-keyboard_arrow_down;
  }
}

.ic-keyboard_arrow_up {
  &::before {
    content: $ic-keyboard_arrow_up;
  }
}

.ic-menu-close {
  &::before {
    content: $ic-menu-close;
  }
}

.ic-menu-open {
  &::before {
    content: $ic-menu-open;
  }
}

.ic-more {
  &::before {
    content: $ic-more;
  }
}

.ic-number-stepper {
  &::before {
    content: $ic-number-stepper;
  }
}

.ic-search-24px {
  &::before {
    content: $ic-search-24px;
  }
}

.ic-search {
  &::before {
    content: $ic-search;
  }
}

.ic-supervisor-account {
  &::before {
    content: $ic-supervisor-account;
  }
}

.ic-time {
  &::before {
    content: $ic-time;
  }
}

.ic-error {
  &::before {
    content: $ic-undefined;
  }
}

.ic-warning {
  &::before {
    content: $ic-warning;
  }
}

.ic-calculator {
  &::before {
    content: $ic-calculator;
  }
}

.ic-days-color {
  &::before {
    content: $ic-days-color;
  }
}

.ic-note {
  &::before {
    content: $ic-note;
  }
}

.ic-color {
  &::before {
    content: $ic-color;
  }
}

.ic-calendar {
  &::before {
    content: $ic-calendar;
  }
}

.ic-info-full {
  &::before {
    content: $ic-info-full;
  }
}

.ic-calendar-end {
  &::before {
    content: $ic-calendar-end;
  }
}

.ic-error-full {
  &::before {
    content: $ic-error-full;
  }
}

.ic-keyboard_arrow_left {
  &::before {
    content: $ic-keyboard_arrow_left;
  }
}

.ic-keyboard_arrow_right {
  &::before {
    content: $ic-keyboard_arrow_right;
  }
}

.ic-close-outline {
  &::before {
    content: $ic-close-outline;
  }
}

.ic-upload {
  &::before {
    content: $ic-upload;
  }
}

.ic-upload-cloud {
  &::before {
    content: $ic-upload-cloud;
  }
}
