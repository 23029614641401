/* stylelint-disable selector-class-pattern */
.collapp-project-list-table {
  .mat-mdc-header-cell,
  .mat-mdc-cell {
    width: auto;
    min-width: 60px;
  }

  .mat-column-projectNumber,
  .mat-column-projectNumberFilter {
    width: 100px;
    min-width: 100px;
  }

  .mat-column-title,
  .mat-column-titleFilter {
    width: 200px;
    min-width: 200px;
  }

  .mat-column-projectManager,
  .mat-column-projectManagerFilter,
  .mat-column-technicalProjectManager,
  .mat-column-technicalProjectManagerFilter {
    width: 160px;
    min-width: 160px;
  }

  .mat-column-submitterUnit,
  .mat-column-submitterUnitFilter,
  .mat-column-emtOrderFromUnit,
  .mat-column-emtOrderFromUnitFilter {
    width: 200px;
    min-width: 200px;
  }

  .mat-column-gateName,
  .mat-column-gateNameFilter {
    width: 200px;
    min-width: 200px;
  }

  .mat-column-customerCountryCode,
  .mat-column-customerCountryCodeFilter,
  .mat-column-placeOfManufacturingCountryCode,
  .mat-column-placeOfManufacturingCountryCodeFilter,
  .mat-column-emtOrderFromCountryCode,
  .mat-column-emtOrderFromCountryCodeFilter {
    width: 200px;
    min-width: 200px;
  }

  .mat-column-status,
  .mat-column-statusFilter {
    width: 100px;
    min-width: 100px;
  }

  .mat-column-projectTypeCode,
  .mat-column-projectTypeCodeFilter {
    width: 160px;
    min-width: 160px;
  }

  .mat-column-payrollCompany,
  .mat-column-payrollCompanyFilter {
    width: 120px;
    min-width: 120px;
  }

  .mat-column-percentDone,
  .mat-column-percentDoneFilter {
    width: 60px;
    min-width: 60px;
  }
}

.collapp-attachments__table {
  .mat-column-fileName {
    width: 100%;
    padding-right: 8px;
    word-wrap: break-word;
  }

  .mat-column-fileType {
    width: 30%;
  }

  .mat-column-fileSize {
    min-width: 90px;
  }

  .mat-column-uploadedOn {
    min-width: 90px;
  }

  .mat-column-uploader {
    width: 40%;
  }

  .mat-column-fileType,
  .mat-column-fileSize,
  .mat-column-uploadedOn,
  .mat-column-uploader {
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mat-column-fileType,
  .mat-column-uploadedOn {
    display: none;
  }

  .mat-column-markAsOutdated {
    max-width: 60px;
    overflow: visible;
    text-align: center;
  }

  .mat-column-uploadedIndication {
    width: 40px;
    text-align: center;
  }

  .mat-column-actions {
    width: 80px;
    min-width: 80px;
  }

  // stylelint-disable selector-max-type,selector-no-qualifying-type
  tr.mat-mdc-footer-row {
    height: auto;
    font-weight: 700;
  }

  td.mat-mdc-footer-cell {
    padding: 16px 8px 8px;
    border-bottom: 0;

    &:empty {
      padding: 0;
    }
  }
  // stylelint-enable selector-max-type,selector-no-qualifying-type

  &--disabled {
    .mat-column-actions {
      width: 52px;
      min-width: 52px;
    }
  }
}

.collapp-available-resource-list-table {
  .mat-column-fullName,
  .mat-column-unit {
    min-width: 200px;
  }

  .mat-column-unit {
    width: 200px;
  }
}

.collapp-project-import-list-table {
  .mat-mdc-header-cell,
  .mat-mdc-cell {
    width: 100px;
    min-width: 100px;
  }

  .mat-column-sapNumber,
  .mat-column-sapNumberFilter {
    width: 100px;
    min-width: 100px;
  }

  .mat-column-title,
  .mat-column-titleFilter {
    width: auto;
  }

  .mat-column-action,
  .mat-column-actionFilter {
    width: 200px;
    min-width: 200px;
    text-align: center;
  }
}

@media only screen and (min-width: $break-large) {
  .collapp-attachments__table {
    .mat-column-fileType,
    .mat-column-uploadedOn {
      display: table-cell;
    }
  }
}
