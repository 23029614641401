@use "sass:color";
@import "../base/variables";

// Contains the styling for the timecards and timesheets:
// Because they are very similar
.time-tracking {
  .collapp-expansion-panel {
    box-shadow: none;
  }
}

.time-tracking__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.time-tracking__title {
  margin-bottom: 0.25rem;
}

.time-sheet-export-btn {
  .mat-mdc-button-wrapper > * { // stylelint-disable-line selector-max-universal
    margin: 0 4px;
  }

  .mat-icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
}

.time-sheet-export-btn--warning .mat-icon {
  color: $collapp-brand-warning;
}

.time-tracking__actions {
  margin-top: 2rem;
  padding-left: 4px;
}

.time-tracking__actions .mat-icon {
  height: 1.4rem;
  color: $collapp-brand-accent;
  font-size: 1.3rem;
}

.time-tracking__actions .mat-button:not(.unchecked) .mat-icon {
  color: $icon-default-color;
}

.time-tracking__actions .mat-button.disabled {
  cursor: not-allowed;

  .mat-icon {
    color: color.adjust($icon-default-color, $lightness: 60%);
  }
}

.time-tracking-breadcrumb {
  display: flex;
  position: relative;
  top: -1rem;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 20px 0 0;
  overflow: hidden;
  list-style: none;
}

.time-tracking-breadcrumb__item {
  display: inline-block;
  position: relative;
  padding-right: $breadcrumb-separator-size;

  &::after {
    content: "›";
    position: absolute;
    top: 50%;
    right: 0;
    width: $breadcrumb-separator-size;
    height: $breadcrumb-separator-size;
    transform: translateY(-50%);
    font-size: 1rem;
    line-height: 1;
    text-align: center;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.time-tracking__link {
  overflow: hidden;
  color: $collapp-text-color-page;
  text-decoration: none;

  &:hover {
    color: $collapp-text-color-hover;
    text-decoration: underline;
  }
}

.time-tracking-row-container {
  padding: 0 1.5rem;
}

.time-tracking-row {
  display: grid;
  grid-template-columns: 25% 12.5% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  min-height: 45px;
  font-size: 0.875rem;
}

.time-tracking-col__state--open {
  .badge {
    background-color: $collapp-time-tracking-state-open-color;
  }
}

.time-tracking-col__state--pending {
  .badge {
    background-color: $collapp-workflow-state-ready-for-change-approval-color;
  }
}

.time-tracking-col__state--done,
.time-tracking-col__state--approved {
  .badge {
    background-color: $collapp-time-tracking-state-approved-color;
  }
}

.time-tracking-col__state--rejected, .time-tracking-col__state--reopen {
  .badge {
    background-color: $collapp-brand-accent;
  }
}

.time-tracking-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.time-tracking-col--title {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-align: left;
}

.time-tracking-col--title-large {
  align-items: start;
  overflow: hidden;
  text-align: left;
}

.time-tracking-col--label {
  box-sizing: border-box;
  align-items: flex-end;
  padding: 0 1.5rem;
  overflow: hidden;
  font-size: 0.8rem;
}

.time-tracking-col--label-right {
  text-align: right;
}

.time-tracking-col__hours {
  .mat-mdc-form-field-infix {
    padding: 0;
  }

  .mat-mdc-input-element {
    padding: 0.4375em 0;
  }
}

.time-tracking-col--total,
.time-tracking-col--day {
  position: relative;
  min-width: 64px;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: -15px;
    width: 15px;
    height: 100%;
    box-shadow: -10px 0 5px -11px inset rgba($black, 0.25);
  }

  .mat-mdc-form-field-infix {
    border-top: 0;
  }

  .mat-mdc-form-field {
    position: relative;
    width: 64px;
    text-align: center;
  }

  .mat-mdc-form-field-error {
    text-align: left;
  }

  .mat-mdc-input-element::-webkit-outer-spin-button,
  .mat-mdc-input-element::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  .mat-form-field--no-underline {
    top: 6px;
  }
}

.time-tracking-col--faded {
  color: $grey;
}

.time-tracking-col__label-pair {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.time-tracking-col--total {
  .time-tracking-col__hours,
  .time-tracking-col__label-pair {
    font-weight: 700;
  }

  .time-tracking-row--non-productive & .time-tracking-col__hours {
    transform: translateY(-8px);
  }
}

.time-tracking-col__week {
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
}

.time-tracking-col__state {
  margin: 0;
}

.time-tracking-col__total {
  font-weight: 700;
}

.time-tracking-row--project {
  width: 100%;

  .time-tracking-col {
    padding: 1rem 0 0.5rem;
  }

  .time-tracking-col--title {
    padding: 0;
    font-weight: 700;
  }
}

.time-tracking-row--separation {
  .time-tracking-col {
    padding-top: 0.5rem;
  }
}

.time-tracking-row--summary {
  height: 80px;

  .time-tracking-col {
    justify-content: space-around;
    border-top: 4px solid transparent;
  }

  .time-tracking-col--today {
    border-top: 4px solid $collapp-brand-accent;
  }

  .time-tracking-col--holiday {
    border-top: 4px solid $collapp-grey;
  }
}

.time-tracking-row--user {
  .person-list-item {
    padding-bottom: 0;
  }

  .person-list-item__name {
    display: none;
  }
}

.time-tracking-row--non-productive,
.time-tracking-row--user,
.time-tracking-row--work-package {
  &:hover {
    padding: 0;
    transition: box-shadow 0.125s ease-in-out;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 25%);
  }
}

.time-tracking-row--bold {
  .time-tracking-col,
  .mat-mdc-form-field {
    font-weight: 700;
  }
}

.time-tracking-users,
.time-tracking-projects {
  .collapp-expansion-panel-body {
    padding: 0 0 1rem;
  }
}

.time-tracking-users {
  .time-tracking-col--title {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .time-tracking__link {
    width: 100%;
    padding-left: 0.85rem;
    overflow: hidden;
  }
}

.time-tracking-row-container--project {
  padding: 0;

  // Has to be more explicit to work
  &.collapp-expansion-panel {
    overflow: visible;
  }

  .collapp-expansion-panel-header__content {
    padding-right: 1.5rem;
  }

  .collapp-expansion-panel-header__divider {
    display: none;
  }

  .collapp-expansion-panel-header {
    overflow: hidden;
  }

  .collapp-expansion-panel-body {
    padding: 0 1.5rem;
  }
}

.time-sheet {
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  height: 100vh;
  padding: 15px;
  overflow: auto;

  // See .stage__outlet-content in the global _scaffolding
  @media (min-width: $screen-sm-min) {
    padding: 20px 15px 15px;
  }

  .app--has-environment-banner & {
    height: calc(100vh - #{$environment-banner-height});
  }

  .time-tracking-col--title {
    min-width: 64px;
    padding-left: 1rem;
  }

  .time-tracking-col--label {
    width: 10%;
    min-width: 100px;
  }
}

.time-sheet__sticky-info {
  position: sticky;
  z-index: 10;
  top: -15px;
  background-color: $white;
  box-shadow: 0 10px 10px 0 $white;

  @media (min-width: $screen-sm-min) {
    // offsetting parent padding-top
    top: -20px;
  }
}

.time-sheet-export-dialog__progress-bar {
  min-height: 32px;
}

@media only screen and (min-width: $break-desktop) {
  .time-tracking-row--user {
    .person-list-item__name {
      display: block;
    }
  }
}

.time-sheet__cross-utilization-toggle {
  margin-bottom: 15px;
  text-align: right;
}

.timesheet__footer {
  padding-right: 0;
}

.time-tracking__footer {
  .mat-mdc-button-base:last-child {
    margin-right: 0;
  }
}
