// This file is for setting global styles
@import "base/variables";
@import "base/icon-variables";
@import "base/mixins";

// Normalize styles across browsers
@import "~normalize.css/normalize.css";

// Import required theming for Material Design
// ! Already imported in `base/variables` !
// @import "~@angular/material/theming";

// Angular 13
// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// @include mat-core;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($theme);

// Override typography for all Angular Material, including mat-base-typography and all components.
// @include angular-material-typography($custom-typography);

// TODO MIGRATION  Angular 15 - TODO MIGRATION PROBLEM
// https://v15.material.angular.io/guide/typography#typography-configs-and-theming
@import "base/angular-material";
@import "~@ng-select/ng-select/themes/material.theme.css";

// Base styles
@import "base/typography";
@import "base/icons";
@import "base/animations";

// Base Layout
@import "layout/scaffolding";

// Layout
@import "layout/forms";
@import "layout/dialog";
@import "layout/tables";
@import "layout/sidebar";
@import "layout/table-columns";
@import "layout/skeleton";

// Components
@import "component/button/button";
@import "component/button/button-theming";
@import "component/time-tracking";
@import "component/column-selector";
@import "component/input";
@import "component/form-attachments";
@import "component/ckeditor";
@import "component/link";
@import "component/tabs";

// Other
@import "other/errors";



// Plus imports for other components in your app.

// stylelint-disable selector-max-type
body {
  color: #1a1a1a;
  font-family: $primary-font;
}

h1,
h2,
h3,
h4,
h5 {
  color: #2d2d2d;
}

/* stylelint-disable-next-line */
@media only screen and (min-width: 800px) {
  .md-sidenav.mat-sidenav-opened,
  .md-sidenav.mat-sidenav-opening {
    
    /* stylelint-disable declaration-no-important */
    box-shadow:
      0 2px 1px -1px rgb(0 0 0 / 8%),
      0 1px 1px 0 rgb(0 0 0 / 7%),
      0 1px 3px 0 rgb(0 0 0 / 14%) !important;
    /* stylelint-enable declaration-no-important */
  }
}

// Angular Material Overwrite
@import "other/angular-material-overwrite";

// NgSelect overwrite
@import "other/ng-select-overwrite";
