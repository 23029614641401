:root {
  /* stylelint-disable */
  // need the px to be compatible with calc()
  --environment-banner-height: 0px;
  /* stylelint-enable */

  --color-white: #ffffff;
}

// ==========================================
// General settings
// ==========================================
// Paths
$assets-path: "/assets";
$fonts-path: "#{$assets-path}/fonts/";

// Breakpoints
$break-small: 320px;
$break-mobile: 800px;
$break-desktop: 1024px;
$break-large: 1200px;

// ==========================================
// Colors
// ==========================================
// General
$white: #ffffff;
$light-grey: #eaeaea;
$dark-grey: #333333;
$grey: #707070;
$collapp-grey: #5b6f80;
$collapp-light-background: #e4e6e8;
$collapp-grey3: #ebebeb;
$black: #000000;
$active: #b1000e;
$active-dark: #000000;
$active-contrast: #ffffff;

// ABB Colors
$collapp-brand-accent: #ff0026;
$collapp-brand-info: #a6aaab;
$collapp-brand-success: #007a23;
$collapp-brand-warning: #ffd100;
$collapp-brand-error: #e86a70;

// ABB Material Palette
// http://mcg.mbitson.com/#!?mcgpalette0=%23ff000f
$collapp-brand-accent-palette: (
  50: #ffe0e5,
  100: #ffb3be,
  200: #ff8093,
  300: #ff4d67,
  400: #ff2647,
  500: #ff0026,
  600: #ff0022,
  700: #ff001c,
  800: #ff0017,
  900: #ff000d,
  A100: #ffffff,
  A200: #fff2f3,
  A400: #ffbfc0,
  A700: #ffa6a7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$collapp-brand-grey-palette: (
  50 : #ebeef0,
  100 : #ced4d9,
  200 : #adb7c0,
  300 : #8c9aa6,
  400 : #748593,
  500 : $collapp-grey,
  600 : #536778,
  700 : #495c6d,
  800 : #405263,
  900 : #2f4050,
  A100 : #9ecfff,
  A200 : #6bb5ff,
  A400 : #389cff,
  A700 : #1f8fff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$collapp-brand-error-palette: (
  50 : #fcedee,
  100 : #f8d2d4,
  200 : #f4b5b8,
  300 : #ef979b,
  400 : #eb8085,
  500 : $collapp-brand-error,
  600 : #e56268,
  700 : #e2575d,
  800 : #de4d53,
  900 : #d83c41,
  A100 : #ffffff,
  A200 : #fff2f3,
  A400 : #ffbfc1,
  A700 : #ffa6a8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Applied Layout Colors
$collapp-text-color-page: #000000;
$collapp-bg-color-page: #f7f8f8;
$collapp-text-color-base: $collapp-grey;
$collapp-bg-color-base: #f7f8f8;
$collapp-text-color-hover: #044166;
$collapp-bg-color-hover: #eef0f2;
$collapp-text-color-selected: #ffffff;
$collapp-bg-color-selected: #004c97;

// Applied Form Colors
$collapp-label-color: $collapp-grey;
$collapp-label-font-weight: 700;
$collapp-select-color-selected: $collapp-brand-accent;
$collapp-select-border-color: $collapp-grey;

// ABB Chart Colors
$collapp-chart-series-a: #6694c1;
$collapp-chart-series-b: #6bb4e0;
$collapp-chart-series-c: #66af85;
$collapp-chart-series-d: #ffe366;
$collapp-chart-series-e: #ff666f;
$collapp-chart-series-f: #c76be0;

// State Colors
$collapp-workflow-state-button-hover-color: #9da9b3;
$collapp-workflow-state-draft-color: #a6aaab;
$collapp-workflow-state-forecasted-color: #a6aaab;
$collapp-workflow-state-issued-color: #a6aaab;
$collapp-workflow-state-confirmed-color: #0099ff;
$collapp-workflow-state-in-progress-color: #0099ff;
$collapp-workflow-state-on-hold-color: #ffcc00;
$collapp-workflow-state-resume-work-color: #ffcc00;
$collapp-workflow-state-scope-change-color: #ffcc00;
$collapp-workflow-state-ready-for-change-approval-color: #ffcc00;
$collapp-workflow-state-in-qa-color: #0099ff;
$collapp-workflow-state-ready-for-approval-color: #33cc00;
$collapp-workflow-state-closed-color: #28b440;
$collapp-workflow-state-deleted-color: #cccccc;
$collapp-time-tracking-state-open-color: #8c9aa5;
$collapp-time-tracking-state-approved-color: #28b440;
$collapp-time-tracking-state-submitted-color: #28b440;
$collapp-time-tracking-state-ready-color: #ffcc00;

// TODO MIGRATION, those variable were used but never defined..
$dark-disabled-text: $collapp-grey;
$dark-primary-text: $collapp-text-color-page;

// ==========================================
// Fonts
// ==========================================
$font-family-sans-serif: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$font-family-serif: "Georgia", "Times New Roman", "Times", serif;
$font-family-monospace: "Menlo", "Monaco", "Consolas", "Courier New", monospace;
$font-family-iconic: "ABBicons";
$font-family-base: $font-family-sans-serif;
$primary-font: $font-family-sans-serif;
$icon-default-color: rgb(0 0 0 / 54%);

// Buttons
// Flat and default button standards
$collapp-button-padding: 0.5em 1.5em !default;
$collapp-button-min-width: 130px !default;
$collapp-button-margin: 0 !default;
$collapp-button-font-size: 0.875rem !default;
$collapp-button-font-weight: 500 !default;
$collapp-button-line-height: 1.25 !default;
$collapp-button-border-radius: 999px !default;
$collapp-button-focus-transition:
  opacity 200ms ease-in-out,
  background-color 200ms ease-in-out !default;

// Stroked Button standards
$collapp-stroked-button-line-height: $collapp-button-line-height !default;

// Stroked button padding is 1px less horizontally than default/flat/raised
// button's padding.
$collapp-stroked-button-padding: calc(0.5em - 1px) calc(1.5em - 1px) !default;

$collapp-button-disabled-color: $collapp-text-color-page !default;
$collapp-button-primary-color: $collapp-brand-accent !default;
$collapp-button-stroked-default-color: #2d2d2d !default;
$collapp-button-stroked-hover-color: #9b9b9b !default;
$collapp-button-flat-default-color: #2d2d2d !default;
$collapp-button-flat-text-color: $white !default;
$collapp-button-sharp-edge-default-color: #2d2d2d !default;
$collapp-button-sharp-edge-text-color: #efefef !default;

// Other components
$collapp-loading-spinner-size: 64px;
$collapp-disabled-text-color: #cccccc;

// ==========================================
// Layout settings (TODO)
// ==========================================
$environment-banner-height: 40px;

$zindex-collapp-split-area: 900 !default;
$zindex-collapp-navigation-fixed-mobile: 1028 !default;
$zindex-collapp-navigation-popover-mobile: 1030 !default;
$zindex-collapp-navigation-backdrop-mobile: 1029 !default;

$zindex-collapp-navigation-fixed-desktop: 1030 !default;
$zindex-collapp-navigation-popover-desktop: 1029 !default;
$zindex-collapp-navigation-backdrop-desktop: 1028 !default;

$zindex-collapp-navbar-mobile: 1025 !default;
$zindex-collapp-navbar-desktop: 1000 !default;

// TODO
$sidepanel-header-background: #eef0f2;
$collapp-notification-background: #eef0f2;

$collapp-sticky-navbar-height: 55px;
$collapp-sticky-navbar-height-sm: 55px;

$sidebar-left-background: #ffffff;
$sidebar-left-text: #262626;
$sidebar-left-circle-border: #bdbbbb;
$sidebar-left-circle-text: #080707;
$sidebar-left-circle-text-disabled: #c9c9c9;

$menu-shadow: rgba(#000000, 0.3);
$navbar-background: $sidebar-left-background;
$sidebar-right-text: #5b6f80;
$sidebar-right-background: #fafafa;
$page-title-line: #fe000f;
$primary-nav-width: 85px;

// Small screen / tablet
// ** Deprecated `$screen-sm-min`. Use Material UI breakpoints!
$screen-sm-min: 768px !default;

// Medium screen / desktop
// ** Deprecated `$screen-md-min`. Use Material UI breakpoints!
$screen-md-min: 992px !default;

// Large screen / wide desktop
// ** Deprecated `$screen-lg-min`. Use Material UI breakpoints!
$screen-lg-min: 1200px !default;

// Week-Selector Navigator
$week-selector-border-color: rgb(200 200 200);

// Breadcrumb
$breadcrumb-separator-size: 20px;

// Work-Package-Detail Settings
$wp-tab-space: 4px;
$wp-action-space: 4px;
$wp-tab-height: 48px;
$wp-header-height: 240px;
$wp-header-state-width: 12px;

// Open Question Batch-Settings (in Pixels)
$batch-tab-size: 24;
$batch-tab-border-size: 2;
