// Remove up-down arrows from number input in Firefox
@-moz-document url-prefix("") {
  // stylelint-disable selector-no-qualifying-type,selector-max-type
  input {
    &[type="number"] {
      // stylelint-disable-next-line property-no-vendor-prefix
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        // stylelint-disable-next-line property-no-vendor-prefix
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  // stylelint-enable selector-no-qualifying-type,selector-max-type
}

.date-range-picker__popup {
  // stylelint-disable declaration-no-important
  width: auto !important;
  height: auto !important;
  // stylelint-enable declaration-no-important
  padding: 16px;

  .mat-calendar-content {
    width: 249px;
    margin-left: 30px;
  }

  .date-range-picker-header--ranges,
  .date-range-picker-header--weeks {
    + .mat-calendar-content {
      display: none;
    }
  }
}

// datepicker with custom Header-component
.mat-datepicker-content-container-with-custom-header {
  .mat-calendar {
    // avoid size-change if the month is on 4 or 5 weeks
    min-height: 304px;
    padding: 16px;
  }

  .mat-calendar-content {
    margin-left: 30px;
  }
}
