// Used when disabling global scrolling.
.collapp-global-scrollblock {
  position: fixed;

  // Necessary for the content not to lose its width. Note that we're using 100%, instead of
  // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
  // that the element had before we made it `fixed`.
  width: 100%;

  // Note: this will always add a scrollbar to whatever element it is on, which can
  // potentially result in double scrollbars. It shouldn't be an issue, because we won't
  // block scrolling on a page that doesn't have a scrollbar in the first place.
  overflow-y: scroll;

  .cdk-global-overlay-wrapper {
    box-sizing: border-box;
    overflow: auto;
    pointer-events: auto;
  }

  .cdk-overlay-pane {
    max-height: none;
    margin: auto;
    padding: 20px 0;
  }
}

.collapp-dialog__header {
  position: relative;
  padding-bottom: 1.5rem;
}

.collapp-dialog__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.collapp-dialog__sub-header {
  font-size: 1.2rem;
}

.collapp-dialog__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  transition: color 0.1s ease-in-out;
  border: 0;
  outline: 0;
  background: transparent;
  color: $collapp-grey;
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    color: $collapp-text-color-page;
  }
}

.collapp-dialog__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapp-dialog__message {
  margin: 0 0 2rem;
}

.collapp-dialog__body {
  padding-bottom: 1.5rem;
}

.collapp-dialog__textarea {
  width: 100%;
}

.collapp-dialog__form-field--compact {
  margin-top: -1rem;
}

.collapp-dialog__recipients {
  margin: 0 0 0 0.3em;
}

.collapp-dialog__recipient {
  display: flex;
  align-items: center;
}

.collapp-dialog__recipient-icon {
  display: flex;
  align-items: center;
  padding-right: 0.25rem;

  .mat-icon {
    width: 25px;
    height: 23px;
    color: $collapp-grey;
  }
}

.collapp-dialog__send-notifications {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding-left: calc(25px + 0.25rem);
}

.collapp-dialog__tabs {
  .mat-mdc-tab-header {
    // stylelint-disable-next-line
    border-bottom: 1px solid rgba(0, 0, 0, 12%);
  }

  .mat-mdc-tab {
    /* stylelint-disable-next-line declaration-no-important */
    flex-grow: 0 !important;
  }

  .mat-mdc-tab-body {
    &.mat-mdc-tab-body-active {
      overflow-y: hidden;
    }
  }

  .mat-mdc-tab-body-content {
    padding: 1rem 0.5rem;
  }
}

.collapp-dialog__alert {
  margin-top: 0.75rem;
}

.collapp-dialog__loading-info {
  display: inline-block;
  margin: 0 0.25rem;
  vertical-align: middle;
}

.collapp-dialog__loading-spinner {
  margin: 0 0 0 0.25rem;
}

.collapp-dialog__loading-spinner,
.collapp-dialog__recipients-loading-spinner {
  display: inline-block;
  vertical-align: middle;
}

.collapp-dialog__footer {
  display: flex;
  align-items: center;
  margin: 0 -1.5rem -1.5rem;
  padding: 1.5rem;
  background-color: $light-grey;

  .collapp-stroked-button,
  .collapp-flat-button,
  .collapp-button {
    margin: 0 0.25rem;

    &:first-child {
      margin-right: -0.25rem;
    }
  }
}

.flex-spacer {
  flex: 1 1 auto;
}

.collapp-dialog--small {
  min-width: 80vw;
}

.collapp-dialog--large {
  min-width: 80vw;

  // min-height: 80vh;
}

.collapp-dialog--info,
.collapp-dialog--error,
.collapp-dialog--prompt {
  max-width: 500px;
}

.mat-mdc-option-search-wrapper {
  display: flex;
  align-items: center;
}

.mat-mdc-option-search-text {
  margin-left: 0.5rem;
}

@media only screen and (min-width: $break-mobile) {
  .cdk-overlay-pane.mat-mdc-dialog-panel.collapp-dialog--small {
    min-width: 50vw;
  }

  .cdk-overlay-pane.mat-mdc-dialog-panel.collapp-dialog--large {
    min-width: 70vw;
  }
}

@media only screen and (min-width: $break-large) {
  .cdk-overlay-pane.mat-mdc-dialog-panel.collapp-dialog--small {
    min-width: 992px;
  }

  .cdk-overlay-pane.mat-mdc-dialog-panel.collapp-dialog--large {
    min-width: 992px;

    // min-height: 40vh;
  }
}
