@use "sass:color";
@import "../../base/variables";

// stylelint-disable property-sort-order,order/properties-order,order/order

.collapp-button,
.collapp-stroked-button {
  background: transparent;

  // Buttons without a background color should inherit the font color. This is necessary to
  // ensure that the button is readable on custom background colors. It"s wrong to always assume
  // that those buttons are always placed inside of containers with the default background
  // color of the theme (e.g. themed toolbars).
  color: inherit;
  font-size: $collapp-button-font-size;
  font-weight: $collapp-button-font-weight;

  &.mat-primary {
    color: $collapp-button-primary-color;
  }

  &.mat-primary,
  &[disabled] {
    &[disabled] {
      color: rgba($collapp-button-disabled-color, 0.26);
    }
  }

  &.mat-primary .mat-mdc-button-focus-overlay {
    background-color: rgba($collapp-button-primary-color, 0.12);
  }

  &.mat-primary .mat-ripple-element {
    background-color: rgba($collapp-button-primary-color, 0.1);
  }

  &[disabled] .mat-mdc-button-focus-overlay {
    background: transparent;
  }
}

.collapp-stroked-button {
  color: $collapp-button-stroked-default-color;

  &.mat-primary {
    color: $collapp-button-primary-color;
  }

  &:hover {
    color: $collapp-button-stroked-hover-color;
    background-color: #E0E0E0;
    
    .mat-mdc-button-focus-overlay {
      opacity: 0.12;
    }
  }
}

.collapp-flat-button {
  background-color: $collapp-button-flat-default-color;

  // Default font and background color when not using any color palette.
  color: $collapp-button-flat-text-color;
  font-size: $collapp-button-font-size;
  font-weight: $collapp-button-font-weight;

  &.mat-primary {
    background-color: $collapp-button-primary-color;
    color: $collapp-button-flat-text-color;
  }

  &.mat-primary,
  &[disabled] {
    &[disabled] {
      background-color: rgba($collapp-button-disabled-color, 0.12);
      color: rgba($collapp-button-disabled-color, 0.26);
    }
  }

  &.mat-primary .mat-ripple-element {
    background-color: rgba(color.adjust($collapp-button-primary-color, $lightness: -35%), 0.1);
  }

  &:hover {
    .mat-mdc-button-focus-overlay {
      opacity: 0.12;
    }
  }
}

.collapp-sharp-edge-button {
  background-color: $collapp-button-sharp-edge-default-color;

  // Default font and background color when not using any color palette.
  color: $collapp-button-sharp-edge-text-color;
  font-size: $collapp-button-font-size;
  font-weight: $collapp-button-font-weight;

  &:hover {
    background-color: color.adjust($collapp-button-sharp-edge-default-color, $lightness: -10%);
    color: $white;

    .mat-mdc-button-focus-overlay {
      opacity: 0;
    }
  }
}

// stylelint-enable property-sort-order,order/properties-order,order/order

.collapp-button--narrow {
  min-width: 0;
}
