@import "variables";

@mixin im($reset: true) {
  // use !important to prevent issues with browser extensions that change fonts
  font-family: $font-family-iconic;
  speak: none;

  @if $reset == true {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
  }
}

@mixin fake-label {
    // var(--mdc-filled-text-field-label-text-color);
    color: rgba(0 0 0 / 60%);

    // font-size: var(--mdc-filled-text-field-label-text-size);
    font-size: 13px;
}

@mixin shade {
  content: "";
  position: absolute;
  z-index: 10;
  top: -7px;
  right: 0;
  left: 0;
  height: 7px;
  background-image: linear-gradient(to top, rgba(#000000, 0.15), rgba(#000000, 0));
}

@mixin table2-row-header-auto-width {
  $buffer: 20px;

  @for $i from 1 through 5 {
    &[data-totals="#{$i}"] {
      /* stylelint-disable */
      width: calc(100vw - #{$cell-width * 7} - #{$cell-width-wide * $i} - #{$primary-nav-width} - #{$side-panel-open-width} - #{$buffer});
      /* stylelint-enable */
    }
  }
}

@mixin table2-row-header-max-width {
  @for $i from 1 through 5 {
    &[data-totals="#{$i}"] {
      max-width: calc(1145px - #{$cell-width-wide * $i});
    }
  }
}
