@import "../base/variables";

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.collapp-mat-tab-badge {
  display: inline-flex;
  position: relative;
  margin-left: -24px;
  vertical-align: middle;

  &--warning {
    color: $collapp-brand-warning;
  }

  &--error {
    color: $collapp-brand-error;
  }
}
