@keyframes blinking {
  0% {
    border: 1px solid transparent;
  }

  10% {
    border: 1px solid $collapp-select-border-color;
  }

  20% {
    border: 1px solid transparent;
  }

  30% {
    border: 1px solid $collapp-select-border-color;
  }

  40% {
    border: 1px solid transparent;
  }

  50% {
    border: 1px solid $collapp-select-border-color;
  }

  60% {
    border: 1px solid transparent;
  }

  70% {
    border: 1px solid $collapp-select-border-color;
  }

  80% {
    border: 1px solid transparent;
  }

  90% {
    border: 1px solid $collapp-select-border-color;
  }

  100% {
    border: 1px solid transparent;
  }
}
