@use "sass:color";

// To customize drop zone, class has to be defined globally
// See: https://github.com/georgipeltekov/ngx-file-drop/issues/76
.collapp-attachments__table {
  width: 100%;
  table-layout: fixed;
}

.collapp-attachments__drop-icon {
  font-size: 3rem;
}

.collapp-attachments__drop {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  margin: 0;
  border: 2px dashed color.adjust($light-grey, $lightness: -7.5%);
  background-color: $light-grey;

  > .ngx-file-drop__content {
    height: 100%;
  }

  .collapp-attachments__drop-icon {
    color: $collapp-grey;
  }

  .btn {
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
    background: transparent;
    cursor: pointer;
  }

  &:hover {
    border-color: color.adjust($light-grey, $lightness: -15%);
    background-color: color.adjust($light-grey, $lightness: -2.5%);

    .collapp-attachments__drop-icon {
      color: $collapp-brand-accent;
    }
  }
}

.collapp-attachments__actions {
  display: flex;
  padding: 1.5rem 0;

  .collapp-button {
    margin: 0 0.5rem;
  }

  .collapp-button:first-child {
    margin-left: -0.5rem;
  }
}

.collapp-attachments__select-status {
  max-width: 10em;
  margin-left: 1em;

  .mat-mdc-text-field-wrapper {
    min-width: 100px;
    height: 46px;
  }

  .mat-mdc-form-field-infix {
    padding-top: 0;
    border-top: 0;
  }
}
