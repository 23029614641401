@import "../../base/variables";

// TODO MIGRATION @import "@angular/material/theming";

@mixin collapp-button-reset {
  border: 0;
  outline: 0;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@mixin collapp-button-base {
  // Make anchors render like buttons.
  display: inline-block;
  position: relative;
  // stylelint-disable property-sort-order,order/properties-order,order/order
  box-sizing: border-box;

  // Sizing.
  min-width: $collapp-button-min-width;
  margin: $collapp-button-margin;
  padding: $collapp-button-padding;

  // Explicitly set the default overflow to `visible`. It is already set
  // on most browsers except on IE11 where it defaults to `hidden`.
  overflow: visible;
  font-weight: 500;
  line-height: $collapp-button-line-height;
  text-align: center;
  text-decoration: none;
  vertical-align: baseline;
  white-space: nowrap;

  // Reset browser <button> styles.
  @include collapp-button-reset;

  // stylelint-enable property-sort-order,order/properties-order,order/order

  &[disabled] {
    cursor: default;
  }

  &.cdk-keyboard-focused,
  &.cdk-program-focused {
    .mat-mdc-button-focus-overlay {
      opacity: 0.12;
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }
}
