// TODO MIGRATION @import "@angular/material/theming";
@import "../../styles/base/variables";

.sidebar-content {
  box-sizing: border-box;
  min-height: 100%;
  padding: 16px 0;
  overflow: hidden;
  background-color: #f2f2f2;
}

.sidebar-nav__item {
  position: relative;
  padding: 0 16px;

  .collapp-wp__tab-badge {
    margin-left: auto;
  }
}

//  .mdc-list-item__content is making ellipsis per default
.sidebar-content .mdc-list-item__content {
  white-space: normal;

  .mdc-list-item__primary-text {
    white-space: normal;
  }
}

/* stylelint-disable-next-line */
a.sidebar-nav__item--active,
a.sidebar-nav__item--active:hover {
  position: relative;
  background-color: $active;
  color: $active-contrast;

  /* stylelint-disable-next-line */
  .mdc-list-item__primary-text,
  .mdc-list-item__primary-text  {color: $active-contrast;}

  &:hover {
    background-color: $active;
  }
}
