@font-face {
  font-family: ABBicons;
  font-style: normal;
  font-weight: 400;
  src:
    url("#{$fonts-path}ABBicons.woff2?cqm6rm") format("woff2"),
    url("#{$fonts-path}ABBicons.woff?cqm6rm") format("woff");
}

// stylelint-disable selector-max-type

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.125rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-size: 0.875rem;
}

// stylelint-enable selector-max-type
