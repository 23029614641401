/* stylelint-disable scss/dollar-variable-pattern */
$ic-calculator: "\e900";
$ic-note: "\e901";
$ic-color: "\e902";
$ic-calendar: "\e903";
$ic-add: "\e907";
$ic-arrow-back: "\e908";
$ic-bug: "\e909";
$ic-checked: "\e91a";
$ic-close: "\e90b";
$ic-delete: "\e90c";
$ic-dropDown: "\e90d";
$ic-edit: "\e90e";
$ic-calendar-end: "\e910";
$ic-event: $ic-calendar-end;
$ic-info-full: "\e913";
$ic-info_outline: "\e922";
$ic-warning: "\e914";
$ic-warning-full: "\e915";
$ic-error: "\e91e";
$ic-error-full: "\e916";
$ic-keyboard_arrow_left: "\e917";
$ic-keyboard_arrow_down: "\e918";
$ic-keyboard_arrow_right: "\e912";
$ic-keyboard_arrow_up: "\e919";
$ic-menu-close: "\e91a";
$ic-menu-open: "\e91b";
$ic-more: "\e91c";
$ic-number-stepper: "\e91d";
$ic-search-24px: "\e91f";
$ic-search: "\e920";
$ic-supervisor-account: "\e921";
$ic-time: "\e923";
$ic-close-outline: "\e924";
$ic-download: "\e925";
$ic-upload: "\e926";
$ic-upload-cloud: "\e927";
$ic-superman: "\e928";
$ic-Return: "\e92a";
$ic-external: "\e92b";
$ic-undefined: $ic-close-outline;
$ic-days-color: "\e904";
