@import "../base/variables";

.app {
  display: block;
  box-sizing: border-box;
  min-height: 100vh;
  max-height: 100vh;
}

.app--has-environment-banner {
  --environment-banner-height: #{$environment-banner-height};

  padding-top: $environment-banner-height;
}

.app-ui {
  display: flex;
  position: relative;
  flex-direction: column;
  transform: translateZ(0);
}

.app__sidebar {
  display: flex;
  z-index: 1500;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 3px 1px 3px -2px rgba(#737373, 0.8);
}

.app__primary-nav {
  display: flex;
  z-index: $zindex-collapp-navigation-fixed-mobile;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
}

.app__navbar {
  position: absolute;
  z-index: $zindex-collapp-navbar-mobile;
  top: 0;
  left: 0;
  width: 100%;
  height: $collapp-sticky-navbar-height;
  transition: height 0.1s ease-in-out;
  background-color: $navbar-background;
  box-shadow: 0 0 24px 0 $menu-shadow;
}

.app-split-area {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}

.split-wrap {
  display: flex;
  position: fixed;
  z-index: $zindex-collapp-split-area;
  top: 0;
  left: 0;
  flex-direction: column;
  width: inherit;
  transition: height 0.25s ease-in-out;
}

.app-main {
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .app--has-environment-banner & {
    height: calc(100vh - #{$environment-banner-height});
  }
}

// Styling of router-outlet html
.app_sidebar-content {
  height: 100%;
  overflow: hidden auto;

  .split--collapsed & {
    display: none;
  }
}

.stage__navbar-content {
  padding: 0 60px;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.navbar-title,
.incident-title {
  position: relative;
  margin: 15px 0 0;
  clear: both;
  overflow: hidden;
  font-size: 20px;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-actions {
  display: flex;
  justify-content: flex-end;
}

.navbar-actions__item {
  &.collapp-column-selector,
  &.collapp-stroked-button,
  &.collapp-flat-button,
  &.collapp-button {
    margin: 0 0.25rem;
  }
}

.stage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .app--has-navbar & {
    padding-top: $collapp-sticky-navbar-height;
  }
}

.stage__aside {
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: 0.8rem;
}

.stage__aside-content {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  overflow: hidden;

  .form-section__body {
    padding-bottom: 1.5rem;
  }

  .person-list-item {
    margin: 0.5rem 0;
  }
}

.stage-aside__highlight,
.stage-aside__sub-highlight,
.stage-aside__label {
  display: block;
}

.stage-aside__highlight {
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 700;
}

.stage-aside__sub-highlight {
  margin: -1.75rem 0 1.5rem;
}

.stage-aside__label {
  margin: 1rem 0 0.5rem;
  font-weight: 700;
}

.stage-aside__label--no-margin {
  margin: 0;
}

.stage__outlet {
  flex-grow: 1;
  height: 100%;
  overflow: hidden auto;
}

.stage__outlet-content {
  margin: 0 0 50px;
  padding: 15px;
}

@media (min-width: $screen-sm-min) {
  .app-ui {
    flex-direction: row;
  }

  .app-split-area {
    width: auto;
    min-width: 85px;

    .app--is-nav-open & {
      min-width: 200px;
    }

    .app--has-sidebar & {
      width: 285px;
    }

    .app--is-nav-open.app--has-sidebar & {
      width: 400px;
    }
  }

  .split-wrap {
    flex-direction: row;
    height: 100vh;

    .app--has-environment-banner & {
      height: calc(100vh - #{$environment-banner-height});
    }
  }

  .app__primary-nav {
    z-index: $zindex-collapp-navigation-fixed-desktop;
    width: $primary-nav-width;

    .app--is-nav-open & {
      width: 200px;
    }
  }

  .app__sidebar {
    display: flex;
    flex-shrink: 1;
    height: auto;
  }

  .app__sidebar-content {
    --mdc-list-list-item-label-text-tracking: normal;

    min-width: 200px;
    height: 100%;
    overflow-y: auto;
    color: $sidebar-left-text;
  }

  // @TODO Temporary until finalized
  .app__sidebar-menu-content {
    margin: -23px -29px 0;

    .app-title {
      margin: 20px 0;
      padding: 0 29px;
    }
  }

  .app__navbar {
    z-index: $zindex-collapp-navbar-desktop;
    left: 100%;
    width: calc(100vw - 100%);
    height: $collapp-sticky-navbar-height-sm;
    box-shadow: 2px 1px 3px 0 rgb(0 0 0 / 15%);
  }

  .stage__navbar-content {
    padding: 0.75rem;
    transition: padding 0.1s ease-in-out;
  }

  .navbar-title,
  .incident-title {
    margin: 10px 0 0;
    font-size: 25px;
  }

  .navbar-title {
    margin: 0;
    padding: 10px 0 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 31px;
      height: 4px;
      background-color: $page-title-line;
    }
  }

  .stage {
    flex-direction: row-reverse;
    height: 100%;

    .app--has-navbar & {
      padding-top: $collapp-sticky-navbar-height-sm;
    }
  }

  .stage__aside {
    position: relative;
    width: 266px;
  }

  .stage__aside-content {
    width: auto;
    min-width: 266px;
    padding: 20px 20px 20px 0;
    background-color: $sidebar-left-background;
  }

  .stage__aside-item {
    width: 100%;
    margin: 0 0 14px;
    padding: 14px;
    border: 1px solid rgb(0 0 0 / 15%);
    background-color: $sidebar-right-background;
  }

  .stage__outlet-content {
    padding: 20px 15px 15px;
  }
}

.w-100 {
  width: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media (min-width: $screen-md-min) {
  .stage__aside {
    width: 300px;
  }
}

@media (min-width: $screen-lg-min) {
  .stage__aside {
    width: 340px;
  }
}
