.collapp__link {
  display: inline-block;
  padding: 0;
  border-bottom: 2px solid transparent;
  outline: none;
  background-color: transparent;
  color: $collapp-text-color-page;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid $collapp-brand-accent;
  }
}
