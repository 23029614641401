.form-section__body {
  .cke_chrome {
    border: 0;
  }

  .cke_top {
    border-bottom: 1px solid $collapp-grey;
    background-color: transparent;
  }

  .cke_bottom {
    border-top: 1px solid transparent;
    background-color: transparent;
  }

  .cke_button__imageselector_icon {
    @include im(true);

    &::before {
      content: $ic-superman;
    }
  }
}

/* stylelint-disable */
form .form-section {
  display: block;
  margin: 1rem 0;
  &:first-child {
    margin-top: 0;
  }
}

input,
.mat-mdc-form-field {
  font-family: "Helvetica Neue", "Helvetica", "Arial", -apple-system;
}

/* stylelint-enable */

.mat-mdc-form-field--no-hint {
  margin-bottom: -1.25rem;
}

.mat-mdc-form-field--no-underline {
  .mat-mdc-form-field-underline {
    background-color: transparent;
  }
}

.mat-mdc-form-field--center .mat-mdc-form-field-wrapper {
  text-align: center;
}

.mat-mdc-form-field-infix {
  .mat-mdc-form-field[fxflex] & {
    width: 20px;
    min-width: 20%;
  }
}

.ng-trigger .mat-mdc-form-field-error {
  // In case you use mat-error with your ng-select the error hint has to be readjusted
  margin-top: -1px;
}

// This is a fix for the form field label not being positioned correctly when also having
// matPrefix elements.
// WARNING: This fix only works for flex-positioned form fields with always floating label,
// though this is the case with most forms here in this app when used correctly.
.mat-mdc-form-field-should-float:not(.mat-mdc-form-field-appearance-outline) {
  .mat-mdc-form-field-flex {
    // Make the form field container positioned instead of the field part
    position: relative;

    // Remove positioning form the field part since the label is inside this wrapper
    .mat-mdc-form-field-infix {
      position: static;
    }

    // Adjust the label position for the new outer wrap `mat-form-field-flex`
    .mat-mdc-form-field-label-wrapper {
      top: 0;
    }
  }
}

.mat-mdc-form-field-prefix,
.mat-mdc-form-field-suffix {
  // Should work in all cases in CollApp to prevent form field prefixes and suffixes from becoming too large
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;

  .mat-mdc-form-field-disabled & {
    color: rgb(0 0 0 / 38%);
  }
}

.unit-defects__add-wrapper .mat-mdc-form-field-suffix {
  overflow: visible;
}

.mat-icon--green {
  color: $collapp-brand-success;
}

.mat-icon--red {
  color: $collapp-brand-error;
}

.mat-icon--yellow {
  color: $collapp-brand-warning;
}

.mat-mdc-option-multiple {
  .mat-pseudo-checkbox-disabled {
    display: none;
  }
}

.mat-custom-hint-container {
  margin: 0.25rem 0;
}

.mat-custom-hint {
  padding: 0.25rem 0;
  font-size: 75%;
}

.mat-warn {
  color: $collapp-brand-warning;
}

.mat-mdc-form-field-hint {
  &.error {
    color: $collapp-brand-error;
  }

  &.warn {
    color: $collapp-brand-warning;
  }
}

.mat-mdc-form-field--full-width {
  width: 100%;
}

.form-actions {
  display: flex;
  flex-direction: row-reverse;

  .collapp-button {
    margin: 0 0.5rem;

    &:first-child {
      margin-right: -0.5rem;
    }
  }
}

.button-footer {
  display: flex;
  align-items: center;
  padding: 1.5rem;

  .collapp-stroked-button,
  .collapp-flat-button,
  .collapp-button { // stylelint-disable-line no-descending-specificity
    margin: 0 0.25rem;

    &:first-child {
      margin-right: -0.25rem;
    }
  }
}

// @TODO Basically a <collap-badge />
.collapp-change-info-suffix {
  display: inline-block;
  min-width: 45px;
  padding: 0.3em 0.5em 0.2em;
  border-radius: 1em;
  background-color: #cccccc;
  color: #ffffff;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

.form__file-input {
  min-width: 100%;
  max-width: 100%;
  cursor: pointer;

  .mat-icon {
    transition: transform 100ms ease;
  }

  &:hover .mat-icon {
    transform: scale(1.125);
  }
}

.mat-calendar-body-preview-end > .mat-calendar-body-cell-content {
  // stylelint-disable-next-line declaration-no-important
  background-color: rgb(0 0 0 / 4%) !important;
}

/* stylelint-disable-next-line */
@media only screen and (min-width: 800px) {
  .form__file-input {
    min-width: 300px;
  }
}

/* stylelint-disable-next-line */
@media only screen and (min-width: 1200px) {
  .form__file-input {
    min-width: 400px;
  }
}
