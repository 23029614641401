/**
  Global overwrite of NgSelect for special cases
*/

// TODO: merge similar styles

// /work-packages
.mdc-text-field {
  padding: 0;
}

.mat-mdc-form-field-infix {
  /* stylelint-disable-next-line */
  padding: 0 !important;
  /* stylelint-disable-next-line */
  width: inherit !important;
}

.ng-select {
  padding-bottom: 0;

  .ng-select-container  {
    min-height: 25px;
    align-items: center;

    &::after {
      // bottom border
      display:none;
    }
  }

  .ng-arrow-wrapper {
    height: 26px;
    /* stylelint-disable-next-line */
    bottom: 1px !important;
    /* stylelint-disable-next-line */
    align-self: center !important;
  }
}

.mdc-dialog__container .ng-select .ng-select-container {
    height: 30px;
    min-height: 30px;
}

.mdc-dialog__container {
  /* stylelint-disable-next-line */
  .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    bottom: 5px;
  }

  .ng-value-container {
    padding-bottom: 0;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  .ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select .ng-select-container .ng-value-container {
    padding-bottom: 0;
  }
}


.mdc-dialog__container .collapp-people-select--multiple .ng-select .ng-select-container {
  height: unset;

  /* stylelint-disable-next-line */
  .ng-value-container {
    margin-top: 5px;
  }
}

.ng-select-multiple {
  padding: 0;
}

.ng-placeholder {
  display: none;
  /* stylelint-disable-next-line */
  color: #0000008a !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  /* stylelint-disable-next-line */
  color: rgba(0,0,0,87%);
}

mat-form-field .ng-select {
  .ng-value-container,
  .ng-arrow-wrapper {

    /* stylelint-disable-next-line */
    padding-top: 0 !important;

    /* stylelint-disable-next-line */
    border-top: 0 !important;
  }
}

:root {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-color: #000000de;
  --mdc-filled-text-field-active-indicator-color: #0000006b;
  --mdc-filled-text-field-label-text-size: 15px;
  --mat-form-field-container-text-size: 15px;
  --mdc-switch-unselected-track-color: #cecece;
  --mdc-switch-unselected-hover-track-color: #cecece;
  --mdc-switch-unselected-focus-track-color: #cecece;
  --mat-table-row-item-label-text-line-height: 1.15;

  .mdc-text-field--filled {
    padding-right: 0;
  }

  /* stylelint-disable-next-line */
  .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: inherit;
  }

  /* stylelint-disable-next-line */
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: white;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper {
    border-top: 0;
  }

}

.ng-clear-wrapper.ng-star-inserted {
  display: flex;
}

/* stylelint-disable-next-line */
.mat-mdc-form-field-infix {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  label {
    display: block;
    position: relative;
  }
}

.ng-value-label {
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: normal;
}

.ng-dropdown-panel {
  /* stylelint-disable-next-line */
  width: auto !important;
}


.page-header__filters {

  /* stylelint-disable-next-line */
  .ng-select-container .ng-arrow-wrapper .ng-arrow {
    padding-bottom: 1.5px;
  }

  /* stylelint-disable-next-line */
  .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper {
    border: 0;
    width: 11px;
  }
}

.ng-select-wrapper--centered .mat-mdc-form-field-infix{
  display: flex;
  justify-content: center;
}

collapp-edit-work-package-form {
  /* stylelint-disable-next-line */
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin: 0;
    padding: 0;
  }

  .mdc-text-field {
    padding-right: 0;
    padding-left: 0;
  }

  .ng-select-container .ng-arrow-wrapper .ng-arrow {
    padding-bottom: 1.5px;
  }

  .ng-select .ng-arrow-wrapper {
    /* stylelint-disable-next-line */
    bottom: 1px !important;
    /* stylelint-disable-next-line */
    align-self: self-end !important;
  }

  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
  }
}

.wp-wizard-dialog__select-project-step1 {
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
  }
}

.edit-unit-dialog {
  /* stylelint-disable-next-line */
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #EAEAEA;
    border-radius: 16px;

    /* stylelint-disable-next-line */
    .ng-value-icon {
      color: #000000;
    }
  }

  /* stylelint-disable-next-line */
  .unit-contract-list-item__notification-mail {
    .ng-select .ng-arrow-wrapper {
      height: 17px;
    }
    /* stylelint-disable-next-line */
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
      margin-bottom: 3px;
      padding: 0 5px;
    }
  }

  &__unit-coordinator {
    width: 32%;
    /* stylelint-disable-next-line */
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
      margin-bottom: 3px;
      padding: 0 5px;
    }

    /* stylelint-disable-next-line */
    .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper {
      height: 100%;
      border-top: 0;
      position: absolute;
      top: 5px;
      right: 14px;
    }

    .ng-select .ng-arrow-wrapper {
      /* stylelint-disable-next-line */
      align-self: flex-start !important;
      bottom: unset;
      top: 0;
    }

    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
      align-self: flex-start;
    }

    .collapp-people-select {
      margin-top: 12px;
    }
  }
}

.new-user-dialog {
  .ng-select .ng-arrow-wrapper {
    /* stylelint-disable-next-line */
    bottom: 1px !important;
    /* stylelint-disable-next-line */
    align-self: self-end !important;
  }
}

.edit-non-human-resource-dialog__capacity-unit {
  .mat-mdc-text-field-wrapper {
    overflow: visible;
    z-index: 999;
  }
}

.collapp-people-select__select,
.edit-non-human-resource-dialog__capacity-unit .ng-dropdown-panel,
.user-create-or-update-form-content__country-select .ng-dropdown-panel {
  margin-top: 12px;
}

collapp-edit-work-package-form,
.new-user-dialog,
.planning-node-form {
  /* stylelint-disable-next-line */
  .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    display: flex;
    bottom: 1px;
  }
}

// bug of vertically-truncated-text while typing in field
collapp-edit-work-package-form,
.edit-project-dialog,
.new-user-dialog collapp-people-select,
.edit-unit-dialog,
.planning-node-form {
  /* stylelint-disable-next-line */
  .ng-select .ng-select-container .ng-value-container .ng-input {
    bottom: unset;
  }
}

.glob_display-flex {
  display: flex;
}

.glob_dialog-form-left65 {
  width: 65.6%;
}

.glob_dialog-form-left35 {
  width: 34.4%;
}

.glob_dialog-form-padding-right30 {
  padding-right: 30px;
}

.level-selector__level {
  .mat-mdc-form-field-infix {
    /* stylelint-disable-next-line declaration-no-important */
    width: 20px !important;
  }
}

// make multi select with grey bg and white text
.collapp-dialog__defects {
  .mat-mdc-form-field-infix  {
    justify-content: end;
  }
  /* stylelint-disable-next-line */
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    color: $white;
    background: $collapp-grey;
    padding: 1px 4px;
    border-radius: 12px;
    margin-bottom: 4px;
    margin-right: 4px;
  }
}
