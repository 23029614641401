@import "../base/variables";

.skeleton-nav {
  display: flex;
  position: absolute;
  flex: 0 0 64px;
  flex-direction: column;
  width: 85px;
  min-height: 100vh;

  .app--has-environment-banner & {
    min-height: calc(100vh - #{$environment-banner-height});
  }
}

.skeleton-nav__top {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.skeleton-nav__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.skeleton-nav__items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.skeleton__menu-icon {
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  margin-top: 10px;
  margin-bottom: 12px;
  border-radius: 50%;
  background-color: rgba(#000000, 0.3);
}

.skeleton__menu-icon-sub {
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  margin-top: 18px;
  border-radius: 50%;
  background-color: rgba(#000000, 0.15);
}

.skeleton-nav__item-profile {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-top: 18px;
  border-radius: 50%;
  background-color: rgba(#000000, 0.15);
}
